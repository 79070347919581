import { Avatar, Box, Card, Flex, Heading, Text, Divider, Icon, Button } from '@rhythm/components';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { Contact, Organization, DefaultService as Service } from '~generated';
import { ResponseError } from '~src/services/patients';

export type DataProp = {
  data: Organization;
};
export function AccountProfile({ data }: DataProp) {
  data?.contacts?.sort((a, b) => a.name.localeCompare(b.name));
  const phone = data?.contacts?.find((contact) => contact.type === Contact.type.PHONE);
  const fax = data?.contacts?.find((contact) => contact.type === Contact.type.FAX);
  const history = useHistory();
  const updateAccount = async () =>
    await Service.organizationControllerUpdateAccountStatus(data?.id);
  const { mutate } = useMutation(updateAccount, {
    onSuccess: () => {
      history.push('/settings');
    },
    onError: async (error: ResponseError) => {
      console.log(error);
    },
  });
  const handleSetInactive = async () => {
    mutate();
  };

  return (
    <Card>
      <Flex direction="column" justifyContent="space-between">
        <Flex direction="row" justifyContent="space-between">
          <Avatar />
          <Icon
            cursor="pointer"
            onClick={() => history.push(`/settings/account/edit/${data?.id}`)}
            boxSize="4"
            color="primary.500"
            icon="edit"
          />
        </Flex>
        <Heading mt="15px" variant="h3" fontWeight="bold" mb="lg">
          {data.name}
        </Heading>
      </Flex>

      <Flex mt="24px">
        <Box width="200px">
          <Flex direction="column" justifyContent="space-between">
            <Text variant="smallCaps" fontWeight="700" color="neutral.800">
              Rhythm Id
            </Text>
            <Text color="#213147" fontWeight="500" mt="4px">
              {data.id}
            </Text>
          </Flex>
        </Box>
        {phone && phone?.contact && (
          <Box>
            <Flex direction="column" justifyContent="space-between">
              <Text variant="smallCaps" fontWeight="700" color="neutral.800">
                Phone
              </Text>
              <Text color="#213147" fontWeight="500" mt="4px">
                {phone && phone.contact}
              </Text>
            </Flex>
          </Box>
        )}
      </Flex>
      <Flex mt="20px">
        <Box width="200px">
          <Flex direction="column" justifyContent="space-between">
            <Text variant="smallCaps" fontWeight="700" color="neutral.800">
              Address
            </Text>
            {data?.address?.line1 && (
              <Text color="#213147" fontWeight="500" mt="4px" width="120px">
                {data.address.line1}
              </Text>
            )}
            {data?.address?.city && (
              <Text color="#213147" fontWeight="500" mt="4px" width="120px">
                {data.address.city}
              </Text>
            )}
            {data?.address?.postalCode && (
              <Text color="#213147" fontWeight="500" mt="4px" width="120px">
                {data.address.postalCode}
              </Text>
            )}
            {data?.address?.state && (
              <Text color="#213147" fontWeight="500" mt="4px" width="120px">
                {data.address.state}
              </Text>
            )}
          </Flex>
        </Box>
        <Box>
          <Flex direction="column" justifyContent="space-between">
            <Text variant="smallCaps" color="neutral.800">
              FAX
            </Text>
            <Text color="#213147" fontWeight="500" mt="4px">
              {fax?.contact ?? 'N/A'}
            </Text>
          </Flex>
        </Box>
      </Flex>
      <Flex pt="30px">
        <Divider />
      </Flex>
      <Flex mt="20px">
        {data?.isActive && <Button onClick={() => handleSetInactive()}>Set to inactive</Button>}
      </Flex>
    </Card>
  );
}

export default AccountProfile;
